import { all, takeEvery /* take, put */ } from 'redux-saga/effects';
import { NAVIGATE_TO_SCREEN } from '../actions/routeActions';
import history from '../history';

function navigate(action) {
  switch (action.type) {
    case NAVIGATE_TO_SCREEN:
      history.push(action.path);
      break;
    default:
      break;
  }
}

export default function* rootSaga() {
  yield all([takeEvery(NAVIGATE_TO_SCREEN, navigate)]);
}
