import get from 'lodash.get';

const apiUrl = process.env.REACT_APP_INVENTORY_API;
const EXPERIENCE_CATEGORIES = [];
const CALENDER_EVENT_PLATFORM = [];
const AMENITY_PARAMETERS = [];
const IMAGE_TAGS = [];
const DISCOUNT_TYPES = [];
const FOOD_CATEGORY = [];
const EXPERIENCE_FULFILLMENT_TYPE = [];
const EXPERIENCE_HIGHLIGHTS_TAG = [];
const EXPERIENCE_TAGS = [];
const SALE_APPLICATION_CONSTRAINT = [];
const SELLABLE_DIGITAL_ITEM_DELIVERY_TYPE = [];
const SELLABLE_ITEM_FULLFILLMENT_TYPE = [];
const SELLABLE_ITEM_NATURE = [];
const SELLABLE_ITEM_TYPE = [];
const ACCESSIBILITY_PARAMETER = [];
const ADDON_CATEGORY_TYPES = [];
const PHONE_TYPES = [];
const SEO_IMAGE_TYPE = [];
const STAY_TYPES = [];
const ADDON_PARENT_TYPES = [];
const SUPPLIER_SUPPORTED_SERVICES = [];
const BED_TYPES = [];
const ROOM_TYPES = [];
const ADDRESS_TYPES = [];
const DESTINATION_TAGS = [];
const STAY_STAR_RATINGS = [];
const COUPON_CATEGORIES = [];
const COUPON_CREATION_TYPES = [];
const BOOKING_PACKAGE_TAGS = [];
const BLOCK_TYPES = [];
const EXPERIENCE_PROJECTS = [];
const COUPON_STATUSES = [];
const SHOW_GENRE = [];
const OCCUPANCY_TYPES = [];
const COUPON_OFFER_TYPES = [];
const EXPERIENCE_FAQ_SECTION = [];
const SHOW_TYPE = [];
const USER_SEGMENT_TYPES = [];
const BOOKING_PACKAGE_VISIBILITY = [];
const SIP_VISIBILITY = [];
const EXPERT_ROLES = [];
const EXPERIENCE_LANGUAGE = [];
const SELLABLE_ITEM_TAGS = [];
const TIMEZONE_ENUMS = [];
fetch(`${apiUrl}/constants/get`)
  .then(response => response.json())
  .then(data => {
    ADDON_CATEGORY_TYPES.push(
      ...Object.values(
        get(data, 'constants.AddOnCategories', {})
      ).map(v => ({ id: v, name: v }))
    );
    ADDON_PARENT_TYPES.push(
      ...Object.values(
        get(data, 'constants.AddOnParent', {})
      ).map(v => ({ id: v, name: v }))
    );
    ADDRESS_TYPES.push(
      ...Object.values(
        get(data, 'constants.AddressTypes', {})
      ).map(v => ({ id: v, name: v }))
    );
    BED_TYPES.push(
      ...Object.values(
        get(data, 'constants.BedType', {})
      ).map(v => ({ id: v, name: v }))
    );
    BLOCK_TYPES.push(
      ...Object.values(
        get(data, 'constants.BlockTypeEnum', {})
      ).map(v => ({ id: v, name: v }))
    );
    BOOKING_PACKAGE_TAGS.push(
      ...Object.values(
        get(data, 'constants.BookingPackageTagEnum', {})
      ).map(v => ({ id: v, name: v }))
    );
    CALENDER_EVENT_PLATFORM.push(
      ...Object.values(
        get(data, 'constants.CalendarEventPlatforms', {})
      ).map(v => ({ id: v, name: v }))
    );
    AMENITY_PARAMETERS.push(
      ...Object.values(
        get(data, 'constants.CommonAmenitiesEnum', {})
      ).map(v => ({ id: v, name: v }))
    );
    IMAGE_TAGS.push(
      ...Object.values(
        get(data, 'constants.ImageVideoTagCloudEnum', {})
      ).map(v => ({ id: v, name: v }))
    );
    COUPON_CATEGORIES.push(
      ...Object.values(
        get(data, 'constants.CouponCategory', {})
      ).map(v => ({ id: v, name: v }))
    );
    COUPON_CREATION_TYPES.push(
      ...Object.values(
        get(data, 'constants.CouponCreationType', {})
      ).map(v => ({ id: v, name: v }))
    );
    COUPON_OFFER_TYPES.push(
      ...Object.values(
        get(data, 'constants.CouponOfferType', {})
      ).map(v => ({ id: v, name: v }))
    );
    COUPON_STATUSES.push(
      ...Object.values(
        get(data, 'constants.CouponStatus', {})
      ).map(v => ({ id: v, name: v }))
    );
    DESTINATION_TAGS.push(
      ...Object.values(
        get(data, 'constants.DestinationTagCloudEnum', {})
      ).map(v => ({ id: v, name: v }))
    );
    DISCOUNT_TYPES.push(
      ...Object.values(
        get(data, 'constants.DiscountType', {})
      ).map(v => ({ id: v, name: v }))
    );
    FOOD_CATEGORY.push(
      ...Object.values(
        get(data, 'constants.DishContents', {})
      ).map(v => ({ id: v, name: v }))
    );
    EXPERIENCE_CATEGORIES.push(
      ...Object.values(
        get(data, 'constants.ExperienceCategories', {})
      ).map(v => ({ id: v, name: v }))
    );
    EXPERIENCE_FAQ_SECTION.push(
      ...Object.values(
        get(data, 'constants.ExperienceFaqSection', {})
      ).map(v => ({ id: v, name: v }))
    );
    EXPERIENCE_FULFILLMENT_TYPE.push(
      ...Object.values(
        get(data, 'constants.ExperienceFulfillmentType', {})
      ).map(v => ({ id: v, name: v }))
    );
    EXPERIENCE_HIGHLIGHTS_TAG.push(
      ...Object.values(
        get(data, 'constants.ExperienceHighlightsTag', {})
      ).map(v => ({ id: v, name: v }))
    );
    EXPERIENCE_PROJECTS.push(
      ...Object.values(
        get(data, 'constants.ExperienceProjectEnum', {})
      ).map(v => ({ id: v, name: v }))
    );
    EXPERIENCE_TAGS.push(
      ...Object.values(
        get(data, 'constants.ExperienceTagsEnum', {})
      ).map(v => ({ id: v, name: v }))
    );
    PHONE_TYPES.push(
      ...Object.values(
        get(data, 'constants.PhoneTypeEnum', {})
      ).map(v => ({ id: v, name: v }))
    );
    ROOM_TYPES.push(
      ...Object.values(
        get(data, 'constants.RoomTypeEnum', {})
      ).map(v => ({ id: v, name: v }))
    );
    SALE_APPLICATION_CONSTRAINT.push(
      ...Object.values(
        get(data, 'constants.SaleApplicationConstraint', {})
      ).map(v => ({ id: v, name: v }))
    );
    SELLABLE_DIGITAL_ITEM_DELIVERY_TYPE.push(
      ...Object.values(
        get(data, 'constants.SellableDigitalItemDeliveryType', {})
      ).map(v => ({ id: v, name: v }))
    );
    SELLABLE_ITEM_FULLFILLMENT_TYPE.push(
      ...Object.values(
        get(data, 'constants.SellableItemFulfillment', {})
      ).map(v => ({ id: v, name: v }))
    );
    SELLABLE_ITEM_NATURE.push(
      ...Object.values(
        get(data, 'constants.SellableItemNature', {})
      ).map(v => ({ id: v, name: v }))
    );
    SELLABLE_ITEM_TYPE.push(
      ...Object.values(
        get(data, 'constants.SellableItemTypes', {})
      ).map(v => ({ id: v, name: v }))
    );
    ACCESSIBILITY_PARAMETER.push(
      ...Object.values(
        get(data, 'constants.accessibilityRatingsField', {})
      ).map(v => ({ id: v, name: v }))
    );
    SEO_IMAGE_TYPE.push(
      ...Object.values(
        get(data, 'constants.SeoImageType', {})
      ).map(v => ({ id: v, name: v }))
    );
    SHOW_TYPE.push(
      ...Object.values(
        get(data, 'constants.ShowBusinessType', {})
      ).map(v => ({ id: v, name: v }))
    );
    SHOW_GENRE.push(
      ...Object.values(
        get(data, 'constants.ShowGenre', {})
      ).map(v => ({ id: v, name: v }))
    );
    OCCUPANCY_TYPES.push(
      ...Object.values(
        get(data, 'constants.StayOccupancyTypes', {})
      ).map(v => ({ id: v, name: v }))
    );
    STAY_STAR_RATINGS.push(
      ...Object.values(
        get(data, 'constants.StayStarRating', {})
      ).map(v => ({ id: v, name: v }))
    );
    STAY_TYPES.push(
      ...Object.values(
        get(data, 'constants.StayTypeEnum', {})
      ).map(v => ({ id: v, name: v }))
    );
    SUPPLIER_SUPPORTED_SERVICES.push(
      ...Object.values(
        get(data, 'constants.SupplierSupportedServices', {})
      ).map(v => ({ id: v, name: v }))
    );
    USER_SEGMENT_TYPES.push(
      ...Object.values(
        get(data, 'constants.UserSegmentType', {})
      ).map(v => ({ id: v, name: v }))
    );
    BOOKING_PACKAGE_VISIBILITY.push(
      ...Object.values(
        get(data, 'constants.BookingPackageVisibility', {})
      ).map(v => ({ id: v, name: v }))
    );
    SIP_VISIBILITY.push(
      ...Object.values(
        get(data, 'constants.SipVisibility', {})
      ).map(v => ({ id: v, name: v }))
    );
    EXPERIENCE_LANGUAGE.push(
      ...Object.values(
        get(data, 'constants.ExperienceLanguageSupport', {})
      ).map(v => ({ id: v, name: v }))
    );
    SELLABLE_ITEM_TAGS.push(
      ...Object.values(
        get(data, 'constants.SellableItemTagsEnum', {})
      ).map(v => ({ id: v, name: v }))
    );
    TIMEZONE_ENUMS.push(
      ...Object.entries(
        get(data, 'constants.TimeZoneEnums', {})
      ).map(([k, v]) => ({ id: v, name: k }))
    );
    EXPERT_ROLES.push(
      ...get(data, 'constants.expertEditableRoles', []).map(v => ({ id: v, name: v }))
    );
  });

export {
  EXPERIENCE_CATEGORIES,
  CALENDER_EVENT_PLATFORM,
  AMENITY_PARAMETERS,
  IMAGE_TAGS,
  DISCOUNT_TYPES,
  FOOD_CATEGORY,
  EXPERIENCE_FULFILLMENT_TYPE,
  EXPERIENCE_HIGHLIGHTS_TAG,
  EXPERIENCE_TAGS,
  SALE_APPLICATION_CONSTRAINT,
  SELLABLE_DIGITAL_ITEM_DELIVERY_TYPE,
  SELLABLE_ITEM_FULLFILLMENT_TYPE,
  SELLABLE_ITEM_NATURE,
  SELLABLE_ITEM_TYPE,
  ACCESSIBILITY_PARAMETER,
  ADDON_CATEGORY_TYPES,
  PHONE_TYPES,
  SEO_IMAGE_TYPE,
  STAY_TYPES,
  ADDON_PARENT_TYPES,
  SUPPLIER_SUPPORTED_SERVICES,
  BED_TYPES,
  ROOM_TYPES,
  ADDRESS_TYPES,
  DESTINATION_TAGS,
  STAY_STAR_RATINGS,
  COUPON_CATEGORIES,
  COUPON_CREATION_TYPES,
  BOOKING_PACKAGE_TAGS,
  BLOCK_TYPES,
  EXPERIENCE_PROJECTS,
  COUPON_STATUSES,
  SHOW_GENRE,
  OCCUPANCY_TYPES,
  COUPON_OFFER_TYPES,
  EXPERIENCE_FAQ_SECTION,
  SHOW_TYPE,
  USER_SEGMENT_TYPES,
  BOOKING_PACKAGE_VISIBILITY,
  SIP_VISIBILITY,
  EXPERT_ROLES,
  EXPERIENCE_LANGUAGE,
  SELLABLE_ITEM_TAGS,
  TIMEZONE_ENUMS,
}

export const SERVICES = [
  { id: 'staffBehaviour', name: 'staffBehaviour' },
  { id: 'overall', name: 'overall' },
  { id: 'wifi', name: 'wifi' },
  { id: 'food', name: 'food' },
];


export const ADDRESS_PARENT_TYPES = [
  { id: 'User', name: 'Creator', },
  { id: 'Show', name: 'Show', },
  { id: 'Organiser', name: 'Organiser', },
  { id: 'SellableItem', name: 'SellableItem', },
  { id: 'SellableItemPrice', name: 'SellableItemPrice', },
  { id: 'Supplier', name: 'Supplier', },
  { id: 'Expert', name: 'Expert', },
]

export const BOOKING_PACKAGE_SOURCE_TYPE = [
  { id: 'SUPPLIERS', name: 'SUPPLIERS', },
  { id: 'STAY', name: 'STAY', }
];

export const ADD_PLACE_SOURCES = [
  { id: 'googlePlaceId', name: 'GOOGLE PLACEID' },
  { id: 'latLong', name: 'LAT & LONG' },
];

export const BOOKING_PACKAGE_MEAL_UPGRADES = [
  { id: 'DEFAULT', name: 'DEFAULT', },
  { id: 'PAID', name: 'PAID', },
];

export const COUPON_KEYS_IN_PAISE = [
  'maxCouponAmountExhaustibleLimit',
  'couponValue',
  'minOrderCartValue'
];

export const COUPON_USER_CONTACT_MEDIUMS = [
  { id: 'PHONE', name: 'PHONE' },
  { id: 'EMAIL', name: 'EMAIL' }
];

export const SHOW_ORGANISERS = [
  { id: 'LAVONNE BAKERY', name: 'LAVONNE BAKERY', },
  { id: 'BIG BREWSKY', name: 'BIG BREWSKY', },
];

export const SHOW_DURATION = [15, 25, 20, 35, 35, 40, 40, 60];


export const INVENTORY_PER_PAGE_OPTIONS = [5, 10, 15, 20];

export const SELLABLE_ITEM_PARENT_TYPE = [
  { id: 'Show', name: 'Show', },
  { id: 'Organiser', name: 'Organiser', },
  { id: 'ExperienceMux', name: 'ExperienceMux', },
  { id: 'Supplier', name: 'Supplier', },
  { id: 'BookingPackage', name: 'BookingPackage', },
];
export const EVENT_DETAIL_PARENT_TYPE = [
  { id: 'ExperienceMux', name: 'ExperienceMux', },
];
