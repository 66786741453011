import { authProvider, dataProvider } from '@fly/core';
import { ConnectedRouter as Router } from 'connected-react-router';
import React from 'react';
import { AuthContext, DataProviderContext, Notification } from 'react-admin';
import { connect } from 'react-redux';
import { Switch } from 'react-router';
import AppRoutes from './containers/routes';
import history from './history';

import './App.css';

function App(props) {
  return (
    <AuthContext.Provider value={authProvider}>
      <DataProviderContext.Provider value={dataProvider}>
        <Router history={history}>
          <Switch>
            <AppRoutes />
          </Switch>
        </Router>
        <Notification />
      </DataProviderContext.Provider>
    </AuthContext.Provider>
  );
}
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.login,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch({ type: '@@auth/LOGOUT_REQUEST' }),
    push: path =>
      dispatch({
        type: '@@app/NAVIGATE_TO_SCREEN',
        path,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
