import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';

import HttpClient from './httpClient';
import {
  getListApis,
  getOneApis,
  getManyApis,
  createApis,
  updateApis,
  deleteApis
} from './apiConfig';
import {
  postRequest,
  getRequest,
  deleteRequest,
  putRequest
} from './helpers';

const containsFile = (data) => {
  if (typeof data !== 'object') return false;
  for (let name in data) {
    const value = data[name];
    if (value instanceof File || containsFile(value)) {
      return true
    }
  }
  return false
}

/**
*
* GET_LIST     => GET http://my.api.url/posts?skip=0&limit=24
* GET_ONE      => GET http://my.api.url/posts/123
* GET_MANY     => POST http://my.api.url/posts/many
* GET_MANY     => GET http://my.api.url/posts/?ids=1&ids=2
* UPDATE       => PUT http://my.api.url/posts/123
* CREATE       => POST http://my.api.url/posts
* DELETE       => DELETE http://my.api.url/posts/123
*/
const dataProvider = ({
  endPoint,
  httpClient = HttpClient
} = {}) => {
  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return async (type, resource, params) => {
    if (type === GET_LIST) {
      if (!getListApis[resource]) return Promise.reject({
        message: `End point for getList not found for resource ${resource}`
      });
      if (resource === 'coupon') {
        return getRequest(getListApis[resource].api, getListApis[resource].getQueryParams(params))
          .then(data => (getListApis[resource].formatResponse(data)));
      }
      return postRequest(getListApis[resource].api, getListApis[resource].getQueryParams(params))
        .then(data => (getListApis[resource].formatResponse(data, params)));
    }
    if (type === GET_ONE) {
      if (!params.id || !getOneApis[resource]) return Promise.reject({
        message: `End point for getOne not found for resource ${resource}`
      });
      if (resource === 'show' || resource === 'eventDetail' || resource === 'sellableItem' || resource === 'sellableItemPrice' || resource === 'sale' || resource === 'chapter' || resource === 'creator' || resource === 'creatorBySlug' || resource === 'address' || resource === 'organiser') {
        return getRequest(`${getOneApis[resource].api}/${(getOneApis[resource].getId(params))}`)
          .then(data => (getOneApis[resource].formatResponse(data)));
      }
      if (resource === 'coupon' || resource === 'experience') {
        return getRequest(`${getOneApis[resource].api}/${(getOneApis[resource].getQueryParams(params))}`)
          .then(data => (getOneApis[resource].formatResponse(data)));
      }
      return postRequest(getOneApis[resource].api, getOneApis[resource].getQueryParams(params))
        .then(data => (getOneApis[resource].formatResponse(data)));
    }
    if (type === GET_MANY) {
      if (!params.ids || !getManyApis[resource]) return Promise.reject({
        message: `End point for getMany not found for resource ${resource}`
      });
      return Promise.all(
        params.ids.map(id => {
          if (resource === 'show' || resource === 'sellableItem' || resource === 'sale' || resource === 'chapter' || resource === 'creator' || resource === 'creatorBySlug' || resource === 'address' || resource === 'organiser') {
            return getRequest(`${getManyApis[resource].api}/${id}`)
              .then(data => (getManyApis[resource].formatResponse(data)));
          }
          if (resource === 'coupon' || resource === 'experience') {
            return getRequest(`${getManyApis[resource].api}/${(getManyApis[resource].getQueryParams(id))}`)
              .then(data => (getManyApis[resource].formatResponse(data)));
          }
          return postRequest(getManyApis[resource].api, getManyApis[resource].getQueryParams(id))
            .then(data => getManyApis[resource].formatResponse(data));
        })
      ).then((data) => ({ data }));
    }
    if (type === GET_MANY_REFERENCE) {
      return Promise.reject({
        message: `End point for getManyReference not found for resource ${resource}`
      });
    }
    if (type === UPDATE) {
      if (containsFile(params.data)) return Promise.reject({
        message: `Please wait the file has not yet uploaded to server`
      });
      if (!params.data || !updateApis[resource]) return Promise.reject({
        message: `End point for update not found for resource ${resource}`
      });
      if (resource === 'experience') {
        return putRequest(updateApis[resource].api, updateApis[resource].getQueryParams(params))
          .then((response) => (updateApis[resource].formatResponse(response, params)));
      }
      if (resource === 'sellableItemPrice') {
        return postRequest('sellableItemPrice/quantityUpdate', { sellableItemPriceId: params.id, changeInQuantity: params.data.deltaQuantity })
          .then(() => postRequest(updateApis[resource].api, updateApis[resource].getQueryParams(params)))
          .then((response) => (updateApis[resource].formatResponse(response, params)));
      }
      return postRequest(updateApis[resource].api, updateApis[resource].getQueryParams(params))
        .then((response) => (updateApis[resource].formatResponse(response, params)));
    }
    if (type === UPDATE_MANY) {
      return Promise.reject({
        message: `End point for updateMany not found for resource ${resource}`
      });
    }
    if (type === CREATE) {
      if (!params.data || !createApis[resource]) return Promise.reject({
        message: `End point for create not found for resource ${resource}`
      });
      if (resource === 'place') {
        return postRequest(createApis[resource].getApi(params), createApis[resource].getQueryParams(params))
          .then((response) => (createApis[resource].formatResponse(response, params)));
      }
      if (resource === 'show' || resource === 'sellableItem'  || resource === 'sellableItemPrice' || resource === 'creator') {
        return putRequest(createApis[resource].api, createApis[resource].getQueryParams(params))
          .then((response) => (createApis[resource].formatResponse(response, params)));
      }
  
      return postRequest(createApis[resource].api, createApis[resource].getQueryParams(params))
        .then((response) => (createApis[resource].formatResponse(response, params)));
    }
    if (type === DELETE) {
      if (!params.id || !deleteApis[resource]) return Promise.reject({
        message: `End point for delete not found for resource ${resource}`
      });
      if (resource === 'experience') {
        return deleteRequest(`${deleteApis[resource].api}/${(deleteApis[resource].getQueryParams(params))}`)
          .then(data => (deleteApis[resource].formatResponse(data, params.previousData)));
      }
      if (resource === 'address' || resource === 'sellableItem' || resource === 'creator') {
        return deleteRequest(`${deleteApis[resource].api}/${(deleteApis[resource].getId(params))}`)
          .then(data => (deleteApis[resource].formatResponse(data, params.previousData)));
      }
      return postRequest(deleteApis[resource].api, deleteApis[resource].getQueryParams(params))
        .then(data => (deleteApis[resource].formatResponse(data, params.previousData)));
    }
    if (type === DELETE_MANY) {
      return Promise.reject({
        message: `End point for deleteMany not found for resource ${resource}`
      });
    }
  };
};

export default dataProvider;