import httpClient from './httpClient';

const endPoint = process.env.REACT_APP_INVENTORY_API + '/';

export const postRequest = (url, params) => {
  return httpClient({
    endPoint,
    url,
    options: {
      method: 'POST',
      body: JSON.stringify(params),
    }
  }).then(response => response.json);
};

export const putRequest = (url, params) => {
  return httpClient({
    endPoint,
    url,
    options: {
      method: 'PUT',
      body: JSON.stringify(params),
    }
  }).then(response => response.json);
};

export const getRequest = (url, params) => {
  return httpClient({
    endPoint,
    url: `${url}${params ? `?${params}` : ''}`,
    options: {
      method: 'GET',
    }
  }).then(response => response.json);
};

export const deleteRequest = (url, params) => {
  return httpClient({
    endPoint,
    url: `${url}${params ? `?${params}` : ''}`,
    options: {
      method: 'DELETE',
    }
  }).then(response => response.json);
};

export const reqPromiseWrapper = (req) => {
  return new Promise((resolve, reject) => {
    req.then(resolve).catch(reject);
  });
};

export const getProfileFromToken = (token) => {
  const jwt = JSON.parse(window.atob(token.split('.')[1]));

  return { id: 'my-profile', ...jwt };
};

export const populateWithId = (data, target = '_id') => {
  if (data && data.hasOwnProperty(target)) return { ...data, id: data[target] };//eslint-disable-line
  return {};
};

export const paiseToRupeeFormatter = (data, target = []) => {
  Object.keys(data).map(key => {
    if (target.includes(key)) {
      data[key] /= 100
    }
  });
  return data;
};

export const rupeeToPaiseFormatter = (data, target = []) => {
  Object.keys(data).map(key => {
    if (target.includes(key)) {
      data[key] *= 100
    }
  });
  return data;
};

export const copyToClipboard = text => {
  const dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};

export const calcSkipLimit = (pagination) => {
  if (pagination) {
    const { perPage, page } = pagination;
    return { skip: (page - 1) * perPage, limit: perPage };
  }
  return null;
}

export const calcSortParams = (sort) => {
  const { field, order } = sort;
  if (field !== 'id') {
    return {
      sortOrder: order,
      sortField: field
    }
  }
  return null;
}