import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
  convertLegacyDataProvider,
} from 'react-admin';

import client from './httpClient';
import inventoryDataProvider from './inventoryDataProvider';

const cleanUpIds = (json, root = true) => {
  if (typeof json === "object" && json !== null) {
    if (typeof json === File) {
      throw new Error('Not yet supported');
    }
    if (json instanceof Array) {
      return json.map(data => cleanUpIds(data, false));
    }
    if (!root && json.hasOwnProperty('id')) {
      return json.id;
    }
    for (let key in json) {
      json[key] = cleanUpIds(json[key], false);
    }
  }
  return json;
}
/**
*
* GET_LIST     => GET http://my.api.url/posts?skip=0&limit=24
* GET_ONE      => GET http://my.api.url/posts/123
* GET_MANY     => POST http://my.api.url/posts/many
* GET_MANY     => GET http://my.api.url/posts/?ids=1&ids=2
* UPDATE       => PUT http://my.api.url/posts/123
* CREATE       => POST http://my.api.url/posts
* DELETE       => DELETE http://my.api.url/posts/123
*/
const dataProvider = ({
  endPoint,
  httpClient = client
} = {}) => {
  /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = '';
    const options = {};
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        url = `/${resource}?${adjustQuery(params)}`;
        break;
      case GET_MANY:
        url = `/${resource}/many`;
        options.method = 'POST';
        options.body = JSON.stringify({ ids: params.ids });
        break;
      case GET_ONE:
        url = `/${resource}/${params.id}`;
        break;
      case UPDATE:
        url = `/${resource}/${params.id}`;
        options.method = 'PUT';
        const { id, created_at, modified_at, deleted_at, version, ...data } = params.data;
        options.body = JSON.stringify(cleanUpIds(data));
        break;
      case CREATE:
        url = `/${resource}`;
        options.method = 'POST';
        options.body = JSON.stringify(cleanUpIds(params.data));
        break;
      case DELETE:
        url = `/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };


  const adjustQuery = (params) => {
    /*
    params = { 
        pagination: { page: {int} , perPage: {int} }, 
        sort: { field: {string}, order: {string} }, 
        filter: {Object}, 
        target: {string}, (REFERENCE ONLY)
        id: {mixed} (REFERENCE ONLY)
    }
    */

    let filter = "";
    // Handle FILTER
    for (const key in params.filter) {
      let value = params.filter[key];
      // Handle SEARCH
      if (key === "q" && value !== '') {
        filter += "&q=" + value
      } else {
        filter += "&filter=" + key + ":" + value;
      }
    }

    // Handle FILTER
    if (params.target && params.id) {
      filter += "&filter=" + params.target + ":" + params.id;
    }
    // Handle SORTING
    const sort = params.sort;
    if (sort.field) filter += "&sort=" + sort.field + "&order=" + sort.order;

    // Handle PAGINATION
    const { page, perPage } = params.pagination;
    const start = (page - 1) * perPage;
    const limit = perPage;
    const range = "skip=" + start + "&limit=" + limit;
    return range + filter;
  }



  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, json } = response;
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        return {
          data: json.records.map(record => {
            record.id = record._id;
            return record;
          }),
          total: json.count,
        };
      case GET_MANY:
        return {
          data: json.map(record => {
            record.id = record._id;
            return record;
          }),
        };
      case CREATE:
        return { data: { ...params.data, _id: json._id, id: json._id } };
      case DELETE:
        return { data: { id: null } };
      default:
        return { data: Object.assign({ ...json }, { id: json._id }) };
    }
  };
  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return async (type, resource, params) => {
    if (type === UPDATE_MANY) {
      const responses = await Promise.all(
        params.ids.map(id => {
          const { created_at, modified_at, deleted_at, version, ...data } = params.data;
          return httpClient({
            endPoint,
            url: `/${resource}/${id}`,
            options: {
              method: 'PUT',
              body: JSON.stringify(data),
            }
          })
        })
      );
      return { data: responses.map(response => response.json) }
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      const responses = await Promise.all(
        params.ids.map(id =>
          httpClient(
            {
              endPoint,
              url: `/${resource}/${id}`,
              options: {
                method: 'DELETE',
              }
            }
          )
        )
      )
      return { data: responses.map(response => response.json) }
    }
    const { url, options } = convertDataRequestToHTTP(
      type,
      resource,
      params
    );
    const response = await httpClient({
      endPoint,
      url,
      options,
    })
    return convertHTTPResponse(response, type, resource, params)
  };
};


const datamiddleware = async (type, resource, params) => {
  if (/^web\//.test(resource)) {
    return await dataProvider({
      endPoint: process.env.REACT_APP_CMS_DOMAIN
    })(type, resource.substr(4), params);
  } else if (/^vibecity\//.test(resource)) {
    return await dataProvider({
      endPoint: process.env.REACT_APP_VIBECITY_DOMAIN
    })(type, resource.substr(9), params);
  } else if (/^inventory\//.test(resource)) {
    return await inventoryDataProvider({
      endPoint: process.env.REACT_APP_INVENTORY_API
    })(type, resource.substr(10), params);
  } else {
    alert(`resource not mapped correctly ${resource}`);
    return await inventoryDataProvider({
      endPoint: process.env.REACT_APP_INVENTORY_API
    })(type, resource, params);
  }
};
const newDataProvider = convertLegacyDataProvider(datamiddleware)
export default newDataProvider;