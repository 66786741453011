import React from 'react';
import ReactDOM from 'react-dom';
import { all, fork } from 'redux-saga/effects';
import { Provider } from 'react-redux';
import { adminReducer, adminSaga, TranslationProvider } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import defaultMessages from 'ra-language-english';
import store from './store';
import { addRoutes, navigateToScreen } from './actions/routeActions';
import { authProvider, dataProvider } from '@fly/core';
import ErrorBoundary from './errorBoundary';
import App from './App';
import routes from './routes';

import './index.css';

import * as serviceWorker from './serviceWorker';

const i18nProvider = polyglotI18nProvider(locale => {
  return defaultMessages;
});

const raSaga = function* rootSaga() {
  yield all([adminSaga(dataProvider, authProvider, i18nProvider)].map(fork));
};

store.injectReducer('admin', adminReducer);
store.injectSaga('admin', raSaga);
store.dispatch(addRoutes(routes));

const theme = createMuiTheme({});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <TranslationProvider locale="en" i18nProvider={i18nProvider}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </TranslationProvider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

window.onload = () => {
  store.dispatch(navigateToScreen(window.location.pathname + window.location.search));
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
