import React from 'react';
import { useAuthState, Loading } from 'react-admin';
import { Redirect } from 'react-router';

const withAuthentication = WrappedComponent => () => {
  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (authenticated) {
    return <WrappedComponent />;
  }
  return <Redirect
    to={{
      pathname: '/login',
    }}
  />
};

export default withAuthentication;
