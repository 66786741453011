
import React from 'react';
import Rollbar from 'rollbar';
import PropTypes from 'prop-types';

const isRollBarEnabeld = !!(process.env.REACT_APP_ROLLBAR_ENABLED && process.env.REACT_APP_ROLLBAR_ENABLED.toLowerCase() === 'true');
const ROLLBAR_ACCESS_TOKEN = process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN;
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      rollbar: new Rollbar({
        accessToken: ROLLBAR_ACCESS_TOKEN,
        source_map_enabled: true,
        captureUncaught: true,
        captureUnhandledRejections: true,
        enabled: isRollBarEnabeld,
        payload: {
          environment: process.env.NODE_ENV
        }
      })
    };
  }

  componentDidCatch(error) {
    if(isRollBarEnabeld){
      this.state.rollbar.error(error);
    }
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <p>Something unexpected occured!</p>
        </div>
      )
    }

    return this.props.children;
  }
}


export default ErrorBoundary;

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};
