import React, { useState, useEffect } from 'react';
import { Loading } from 'react-admin';
import { addRoutes } from '../actions/routeActions';
import store from '../store';

const injectRoutes = (routes, store) => {
  store.dispatch(addRoutes(routes));
};

const DynamicImport = ({ load, ...props }) => {
  const [Component, setComponent] = useState(() => () => <Loading />);
  useEffect(() => {
    load().then(module => {
      const { routes, component, reducers, sagas } = module;
      if (reducers) {
        reducers.forEach(reducer => {
          store.injectReducer(reducer.name, reducer.fn);
        });
      }
      if (sagas) {
        sagas.forEach(saga => {
          store.injectSaga(saga.name, saga.fn);
        });
      }

      if (routes) {
        injectRoutes(routes, store);
      }
      setComponent(() => component || module.default);
    })
  }, []);

  return <Component {...props} />
}

export default DynamicImport;