import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
// import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import routeReducer from './reducers/routeReducer';
import history from './history';
import rootSaga from './sagas';
import {
  USER_LOGOUT,
} from 'react-admin';

const sagaMiddleware = createSagaMiddleware();

const createReducer = asyncReducers =>
  combineReducers({
    route: routeReducer,
    router: connectRouter(history),
    ...asyncReducers,
  });

// runSaga is middleware.run function
// rootSaga is a your root saga for static saagas
function createSagaInjector(runSaga) {
  // Create a dictionary to keep track of injected sagas
  const injectedSagas = new Map();

  const isInjected = key => injectedSagas.has(key);

  const injectSaga = (key, saga) => {
    // We won't run saga if it is already injected
    if (isInjected(key)) return;

    // Sagas return task when they executed, which can be used
    // to cancel them
    const task = runSaga(saga);

    // Save the task if we want to cancel it in the future
    injectedSagas.set(key, task);
  };
  return injectSaga;
}

const composeEnhancer =
  (process.env.NODE_ENV === 'development' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const resettableAppReducer = (state, action) =>
  createReducer(action.type !== USER_LOGOUT ? state : undefined, action);
const store = (() => {
  const store = createStore(
    resettableAppReducer,
    composeEnhancer(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
  );

  store.asyncReducers = {};

  store.injectReducer = (name, asyncReducer) => {
    store.asyncReducers[name] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };

  store.injectSaga = createSagaInjector(sagaMiddleware.run);
  // Inject the root saga as it a staticlly loaded file,
  store.injectSaga('root', rootSaga);
  return store;
})();

export default store;
