import React from 'react';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import withPermission from './hocs/withPermission';
import withAuthentication from './hocs/withAuthentication';
import DynamicImport from './components/DynamicImport';
import history from './history';
import {
  Loading,
  usePermissions,
} from 'react-admin';

const Auth = props => (
  <DynamicImport
    load={() => import(/* webpackChunkName: "auth" */ '@fly/auth')}
    {...props}
    history={history}
  />
);

const CMS = props => (
  <DynamicImport
    load={() => import(/* webpackChunkName: "web" */ '@fly/web')}
    {...props}
    history={history}
  />
);

const Vibecity = props => (
  <DynamicImport
    load={() => import(/* webpackChunkName: "web" */ '@fly/vibecity')}
    {...props}
    history={history}
  />
);

const Inventory = props => (
  <DynamicImport
    load={() => import(/* webpackChunkName: "inventory" */ '@fly/inventory')}
    {...props}
    history={history}
  />
);

const Home = props => {
  const { loading, permissions } = usePermissions();
  if (loading) {
    return <Loading />;
  }
  if (!permissions) return <Link to="/login" >Login</Link>;
  return <ul style={{
    lineHeight: '30px',
    paddingLeft: '30px',
  }}>
    {permissions.isContentManager && (<li><Link to="/web" >CMS</Link></li>)}
    {(permissions.isContentManager || permissions.isAdmin) && (<li><Link to="/vibecity" >Vibecity</Link></li>)}
    {(
      permissions.isAdmin
      || permissions.isCouponCrud
      || permissions.isFiltrationTeamLead
      || permissions.isInventorySuperAdmin
      || permissions.isInventoryExpertCrud
      || permissions.isInventoryVibeCityCrud
      || permissions.isTeamLead
    ) && (<li><Link to="/inventory" >Inventory</Link></li>)}
  </ul>
}

export default {
  routes: [
    <Route
      exact
      path="/login"
      component={Auth}
      key="auth-component"
    />,
    <Route
      exact
      path="/"
      component={withAuthentication(Home)}
      key="home-component"
    />,
    <Route
      path="/(web|configuration)"
      component={withPermission(CMS, ['isContentManager'])}
      key="web-component"
    />,
    <Route
      path="/vibecity"
      component={withPermission(Vibecity, ['isContentManager', 'isAdmin'])}
      key="vibecity-component"
    />,
    <Route
      path="/inventory"
      component={withPermission(Inventory, ['isAdmin','isCouponCrud','isFiltrationTeamLead','isInventorySuperAdmin','isInventoryExpertCrud','isInventoryVibeCityCrud','isTeamLead'])}
      key="inventory-component"
    />,
  ],
};
