import React from 'react';
import { compose } from 'redux';
import { usePermissions, Loading } from 'react-admin';
import withAuthentication from './withAuthentication';

const withPermission = (WrappedComponent, checks) => () => {
  const { loading, permissions } = usePermissions();
  if (loading) {
    return <Loading />;
  }
  if(permissions && checks.some((permission) => permissions[permission])){
    return <WrappedComponent />;
  }
  return <div>403</div>
};

export default compose(withAuthentication, withPermission);
