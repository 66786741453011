import qs from 'query-string';
import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  convertLegacyAuthProvider,
} from 'react-admin';
import _ from 'lodash';
import Storage from './storage';

const authApiUrl = process.env.REACT_APP_AUTH_DOMAIN === '/' ? window.location.origin : process.env.REACT_APP_AUTH_DOMAIN;

function urlJoin(a, b) {
  return _.trimEnd(a, '/') + '/' + _.trimStart(b, '/');
}
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
const cleanup = () => {
  // Remove the ?code&state from the URL
  window.history.replaceState(
    {},
    window.document.title,
    window.location.origin + (window.location.pathname === '/' ? '' : window.location.pathname)
  );
}

const uriEncodedString = (hash) => Object.keys(hash).map((key) => {
  return encodeURIComponent(key) + '=' + encodeURIComponent(hash[key]);
}).join('&');

const authProvider = async (type, params = {}) => {
  const search = qs.parse(window.location.search);
  if (type === AUTH_LOGIN) {
    // 1. Redirect to the issuer to ask authentication
    if (!search.code) {
      const response = await fetch(urlJoin(authApiUrl, 'expert/oauth/google/getAuthUrl'), {
        method: 'POST',
        headers: {
          'x-pathname': window.location.pathname,
        },
      });
      if (response.ok) {
        const json = await response.json();
        window.location.href = json.redirectUrl;
      }
      return; // Do not return anything, the login is still loading
    }

    const response = await fetch(`${authApiUrl}/expert/oauth/google/getToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'x-pathname': window.location.pathname,
      },
      body: uriEncodedString(search)
    });
    if (response.ok) {
      const json = await response.json();
      Storage.setItem('token', json.token);
      Storage.setItem('expert', JSON.stringify(json.expert));
    } else {
      await sleep(1000);
    }
    cleanup();
    return;
  }

  if (type === AUTH_LOGOUT) {
    if (!Storage.getItem('token')) {
      return;
    }
    try {
      const response = await fetch(
        urlJoin(authApiUrl, 'expert/oauth/logOut'), {
        method: 'POST',
        headers: {
          'x-access-token': Storage.getItem('token'),
          'x-pathname': window.location.pathname,
        },
      })
      if (response.ok) {
        console.log('Logout gracefully')
      } else {
        console.log('Logout not so gracefully');
      }
    } catch (er) {
      console.log('Logout not so gracefully');
    }
    Storage.removeItem('token');
    Storage.removeItem('expert');
  }
  if (type === AUTH_ERROR) {
    if (params.status === 401) {
      throw params;
    }
    return;
    // throw Error("Authentication failed");
  }

  if (type === AUTH_CHECK) {
    const token = Storage.getItem('token');
    if (!token) {
      throw { redirectTo: '/login' }
    }

    // This is specific to the Google authentication implementation
    // const jwt = getProfileFromToken(token);
    // const now = new Date();
    // if (now.getTime() > (jwt.exp * 1000)) throw Error('Token expired')
    return;
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const expert = JSON.parse(Storage.getItem('expert'));
    const permissions = expert.roles;
    return {
      isInventory: permissions?.some(role => role.match(/INVENTORY_*/)),
      isInventorySuperAdmin: permissions?.includes('INVENTORY_SUPER_ADMIN'),
      isInventoryExpertCrud: permissions?.includes('INVENTORY_EXPERT_CRUD'),
      isInventoryVibeCityCrud: permissions?.includes('INVENTORY_VIBE_CITY_CRUD'),
      isFiltrationTeamLead: permissions?.includes('FILTRATION_TEAM_LEAD'),
      isTeamLead: permissions?.some(role => role.includes('TEAM_LEAD')),
      isAdmin: permissions?.includes('ADMIN'),
      isContentManager: permissions?.includes('CONTENT_MANAGER'),
      isCouponCrud: permissions?.includes('COUPON_CRUD'),
    }
  }
  return;
}
const newAuthProvider = convertLegacyAuthProvider(authProvider);
export default newAuthProvider;
