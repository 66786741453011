import { fetchUtils } from 'react-admin';
import Storage from './storage';

const cmsApiUrl = process.env.REACT_APP_CMS_DOMAIN === '/' ? window.location.origin : process.env.REACT_APP_CMS_DOMAIN;

const httpClient = ({
  endPoint = cmsApiUrl,
  url,
  options = {},
}) => {
  if (!Storage.getItem('token')) {
    return Promise.reject('Token unavailable');
  }
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
    });
    const token = Storage.getItem('token');
    options.headers.set('x-access-token', token);
  }
  return fetchUtils.fetchJson(endPoint + url, options);
};

export default httpClient;