export const ADD_ROUTES = '@@app/ADD_ROUTES';
export const NAVIGATE_TO_SCREEN = '@@app/NAVIGATE_TO_SCREEN';

export const addRoutes = routes => ({
  type: ADD_ROUTES,
  routes,
});

export const navigateToScreen = path => ({
  type: NAVIGATE_TO_SCREEN,
  path,
});
